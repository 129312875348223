import { Controller } from "@hotwired/stimulus"
import fr from "../../../locales/fr.json"
import en from "../../../locales/en.json"

// Connects to data-controller="language"
export default class extends Controller {
  static targets = [
    // alert
    "alertTitle", "alertParagraph", "alertButton",

    // header
    "logo", "navbarFirstLink", "navbarSecondLink", "navbarThirdLink", "navbarFourthLink",
    "navbarFifthLink", "banner", "headerButton",

    // section#issuu
    "issuuTitle", "issuuParagraph",

    // section#landing
    "landingTitle", "landingFirstCardTitle", "landingFirstCardParagraph", "landingFirstCardImage",
    "landingSecondCardTitle", "landingSecondCardParagraph", "landingSecondCardImage",
    "landingThirdCardTitle", "landingThirdCardParagraph", "landingThirdCardImage",

    // section#articles
    "articlesTitle",

    // section#instructions
    "instructionsTitle", "instructionsImage", "instructionsFirstCardTitle", "instructionsFirstCardParagraph",
    "instructionsSecondCardTitle", "instructionsSecondCardParagraph", "instructionsThirdCardTitle",
    "instructionsThirdCardParagraph",

    // section#Shops
    "shopsTitle", "shopsParagraph", "shopsSpan",

    // section#create
    "createTitle", "createParagraph", "createButton",

    // footer
    "footerTitle", "footerTerms", "footerContact", "footerRedirect"
  ]

  connect() {
    this.locales = { fr, en }

    const navigatorLanguage = navigator.language.slice(0, 2)
    if (navigatorLanguage === "en") this.language = "en"
    else if (navigatorLanguage === "fr") this.language = "fr"
    else this.language = null

    this.language = localStorage.getItem("language") || this.language || "fr"
    this.#fillHTML()
  }

  setLanguageToEnglish() {
    this.language = "en"
    localStorage.setItem("language", "en")
    this.#fillHTML()
  }

  setLanguageToFrench() {
    this.language = "fr"
    localStorage.setItem("language", "fr")
    this.#fillHTML()
  }

  toggleLanguage() {
    const toggle = this.language === "fr" ? "en" : "fr"
    this.language = toggle
    localStorage.setItem("language", toggle)
    this.#fillHTML()
  }

  #fillAlert(data) {
    this.alertTitleTarget.innerText = data.title
    this.alertParagraphTarget.innerText = data.paragraph
    this.alertButtonTarget.innerText = data.button
  }

  #fillArticles(data) {
    this.articlesTitleTarget.innerText = data.title
  }

  #fillCreate(data) {
    this.createTitleTarget.innerText = data.title
    this.createParagraphTarget.innerText = data.paragraph
    this.createButtonTarget.innerText = data.button
  }

  #fillFooter(data) {
    this.footerTitleTarget.innerText = data.title
    this.footerTermsTarget.innerText = data.terms
    this.footerContactTarget.innerText = data.contact
    this.footerRedirectTarget.innerText = data.redirect
  }

  #fillHeader(data) {
    this.logoTarget.alt = data.logoAlt

    this.navbarFirstLinkTarget.innerText = data.firstLink
    this.navbarSecondLinkTarget.innerText = data.secondLink
    this.navbarThirdLinkTarget.innerText = data.thirdLink
    this.navbarFourthLinkTarget.innerText = data.fourthLink
    this.navbarFifthLinkTarget.innerText = data.fifthLink

    // eslint-disable-next-line global-require
    if (this.language === "fr") this.bannerTarget.src = require("../../images/banner.fr.jpg")
    // eslint-disable-next-line global-require
    else this.bannerTarget.src = require("../../images/banner.en.jpg")
    this.bannerTarget.alt = data.bannerAlt

    this.headerButtonTarget.innerText = data.button
  }

  #fillInstructions(data) {
    this.instructionsImageTarget.alt = data.imageAlt
    this.instructionsTitleTarget.innerText = data.title

    this.instructionsFirstCardTitleTarget.innerText = data.firstCard.title
    this.instructionsFirstCardParagraphTarget.innerText = data.firstCard.paragraph

    this.instructionsSecondCardTitleTarget.innerText = data.secondCard.title
    this.instructionsSecondCardParagraphTarget.innerText = data.secondCard.paragraph

    this.instructionsThirdCardTitleTarget.innerText = data.thirdCard.title
    this.instructionsThirdCardParagraphTarget.innerText = data.thirdCard.paragraph
  }

  #fillIssuu(data) {
    this.issuuTitleTarget.innerText = data.title
    this.issuuParagraphTarget.innerText = data.paragraph
  }

  #fillLanding(data) {
    this.landingTitleTarget.innerText = data.title

    this.landingFirstCardTitleTarget.innerText = data.firstCard.title
    this.landingFirstCardParagraphTarget.innerText = data.firstCard.paragraph
    this.landingFirstCardImageTarget.alt = data.firstCard.imageAlt

    this.landingSecondCardTitleTarget.innerText = data.secondCard.title
    this.landingSecondCardParagraphTarget.innerText = data.secondCard.paragraph
    this.landingSecondCardImageTarget.alt = data.secondCard.imageAlt

    this.landingThirdCardTitleTarget.innerText = data.thirdCard.title
    this.landingThirdCardParagraphTarget.innerText = data.thirdCard.paragraph
    this.landingThirdCardImageTarget.alt = data.thirdCard.imageAlt
  }

  #fillShops(data) {
    this.shopsTitleTarget.innerText = data.title
    this.shopsParagraphTarget.innerText = data.paragraph
    this.shopsSpanTarget.innerText = data.span
  }

  #fillHTML() {
    const data = this.locales[this.language]
    this.#fillAlert(data.alert)
    this.#fillHeader(data.header)
    this.#fillIssuu(data.issuu)
    this.#fillLanding(data.landing)
    this.#fillArticles(data.articles)
    this.#fillInstructions(data.instructions)
    this.#fillShops(data.shops)
    this.#fillCreate(data.create)
    this.#fillFooter(data.footer)
  }
}
