{
  "alert": {
    "title": "Boutique fermée",
    "paragraph": "Cette boutique n'est malheureusement plus disponible. Si vous désirez vous munir d'un de nos articles veuillez nous contacter par email.",
    "button": "Contactez-nous"
  },

  "header": {
    "logoAlt": "logo de Cubification",
    "firstLink": "LA MÉTHODE",
    "secondLink": "LE LIVRE",
    "thirdLink": "À PROPOS",
    "fourthLink": "CRÉER SON ATELIER",
    "fifthLink": "BOUTIQUE",
    "bannerAlt": "couverture du livre 'Jouez l'inoovation' de Hélène Michel",
    "button": "Disponible maintenant"
  },

  "issuu" : {
    "title": "Parcourez les premières pages gratuitement !",
    "paragraph": "Avec son format original, « Jouez L'innovation : La méthode Cubification » d'Hélène Michel vous donne les clés pour rendre tangibles et manipulables des situations complexes. En utilisant les leviers de motivation et les mécanismes du jeu, la méthode (ré)implique les individus, ouvre le processus d'innovation et fait émerger des solutions faisables, originales ou disruptives."
  },

  "landing": {
    "title": "A vos marques, Prêt ? Jouez !",
    "firstCard": {
      "title": "Accessible",
      "paragraph": "Vous ne savez pas résoudre au Rubik's Cube ? Tant mieux ! La méthode Cubification est faite pour vous. Il ne s'agit pas ici d'apprendre à le faire, mais à le détourner. Alors, quelle est la différence entre le défi du Rubik's cube et la méthode que nous vous proposons ici ? Il ne s'agit pas ici de résoudre le cube, mais plutôt de le « disrupter ».",
      "imageAlt": "icone de médaille coché"
    },
    "secondCard": {
      "title": "Prêt à l'emploi",
      "paragraph": "Afin de rendre tangible votre défi, associez chacune des faces du cube à un levier du changement. La méthode vous permettra de générer des idées en fonction de 6 dimensions - une dimension pour chaque face du cube - représentant les composants et leviers essentiels de votre nouvelle offre de produits ou services.",
      "imageAlt": "icone de fusée"
    },
    "thirdCard": {
      "title": "Déjà adoptée",
      "paragraph": "Réunissant gaming, créativité et storytelling, la méthode Cubification a déjà été utilisée par des milliers de personnes. Utilisée aussi bien en développement personnel, entrepreneuriat, business development ou en formation, la méthode a été testée sur des terrains de jeu extrêmement variés : incubateurs, grandes écoles, petites et grandes entreprises, ainsi que de grandes organisations internationales.",
      "imageAlt": "icone de montagne"
    }
  },

  "articles": {
    "title": "Ils en parlent"
  },

  "instructions": {
    "title": "Comment s'organise le livre",
    "imageAlt": "icone de livre",
    "firstCard": {
      "title": "PRINCIPES",
      "paragraph": "Comment fonctionne la gamification ? Quels sont les mécanismes de jeu permettant d'impliquer les individus dans des tâches complexes ? Quels avantages peuvent en retirer les entreprises ? Dans quelles conditions mettre la gamification en œuvre ? Cette partie décritet illustre les principes de la gamification en donnant de nombreux exemples professionnels."
    },
    "secondCard": {
      "title": "MÉTHODE",
      "paragraph": "Comment fonctionne la méthode Cubification ? Quel est son modèle théorique ? Comment procéder pour la mettre en œuvre ? Cette partie détaille les fondements de la méthode et accompagne, pas à pas, les étapes de mise en œuvre d'une session, depuis les ice-breakers jusqu'à l'analyse des resultats."
    },
    "thirdCard": {
      "title": "TERRAINS",
      "paragraph": "Dans quel contexte utiliser la méthode ? Cette partie détaille 7 terrains de jeux différents en donnant des illustrations et conseils de mise en œuvre. Du développement personnel en passant par la formation au management de l'innovation et à l'entrepreneuriat, découvrez des cas concrets d'utilisation de la méthode dans des situations variées."
    }
  },

  "shops": {
    "title": "Obtenez votre livre aujourd'hui",
    "paragraph": "Prenez part dès maintenant au phénomène global de la gamification appliquée à l'innovation et ne manquez pas le début de cette nouvelle vague.",
    "span": "Disponible aussi en format ebook !"
  },

  "create": {
    "title": "Créer son atelier",
    "paragraph": "Mettez en application la méthode Cubification et engagez vos collaborateurs en organisant un atelier sur votre challenge d'innovation. Du matériel sur mesure, des kits prêts à l'emploi, des guides pratiques de préparation et de facilitation sont là pour que votre atelier soit un succès !",
    "button": "Accéder à la boutique"
  },

  "footer": {
    "title": "Réalisé avec ❤ à Paris",
    "terms": "Mentions légales",
    "contact": "Contactez-nous",
    "redirect": "Website in english"
  }
}
