{
  "alert": {
    "title": "Shop closed",
    "paragraph": "This store is unfortunately no longer available. If you wish to acquire one of our articles please contact us by email.",
    "button": "Contact us"
  },

  "header": {
    "logoAlt": "Cubification logo",
    "firstLink": "METHOD",
    "secondLink": "BOOK",
    "thirdLink": "ABOUT",
    "fourthLink": "WORKSHOP",
    "fifthLink": "SHOP",
    "bannerAlt": "cover of 'Innovation is a game by' Hélène Michel",
    "button": "Now available"
  },

  "issuu" : {
    "title": "Discover the first pages for free!",
    "paragraph": "With its original format, Hélène Michel's « Innovation is a game: The Cubification method » gives you the keys to make complex situations tangible and manipulable. Using the motivation levers and the mechanisms of games, the method involves individuals, opens the innovation process and brings out feasible, original or disruptive solutions."
  },

  "landing": {
    "title": "Ready, set, play!",
    "firstCard": {
      "title": "Accessible",
      "paragraph": "If you are not able to solve a Rubik's Cube and you don't intend to solve it, this game is for you! A Rubik's Cube is used to solve a problem; players try to recreate the cube by arranging the six colors. With Cubification, you use the cube in a totally different way. No longer to solve your situation, but rather to \"disrupt\" it.",
      "imageAlt": "checkmark icon"
    },
    "secondCard": {
      "title": "Ready to use",
      "paragraph": "In order to make you challenge tangible, you will create your own model based on 6 dimensions, one dimension for each side of the cube representing the essential components of your new product or service offer.",
      "imageAlt": "rocket icon"
    },
    "thirdCard": {
      "title": "Already widely used",
      "paragraph": "Bringing together gaming, creativity and storytelling, the Cubification method has already been used by thousands of people. Used both in personal development, entrepreneurship, business development and training, the method has been tested on varied playgrounds: incubators, grandes écoles, small and large companies, as well as large international organizations.",
      "imageAlt": "mountain icon"
    }
  },

  "articles": {
    "title": "Press releases"
  },

  "instructions": {
    "title": "How is the book organized?",
    "imageAlt": "book icon",
    "firstCard": {
      "title": "PRINCIPLES",
      "paragraph": "How does gamification work? What are the game mechanisms for involving individuals in complex tasks? What benefits can companies get? Under which conditions will gamification be implemented? This part describes and illustrates the principles of gamification by giving many professional examples."
    },
    "secondCard": {
      "title": "METHOD",
      "paragraph": "How does the Cubification method work? What is its theoretical model? How to implement it? This part details the foundations of the method and guides, step by step, the different stages of a session, from the ice-breakers to the analysis of the results."
    },
    "thirdCard": {
      "title": "PLAYGROUNDS",
      "paragraph": "In which context to use the method? This part details 7 different playgrounds by giving illustrations and implementation tips. From personal development to innovation management and entrepreneurship training, discover concrete use cases of the method in various situations."
    }
  },

  "shops": {
    "title": "Get your book today",
    "paragraph": "Take part to the global phenomenon of gamification applied to innovation and do not miss the beginning of this new wave.",
    "span": "Also available in ebook!"
  },

  "create": {
    "title": "Create your workshop",
    "paragraph": "Implement the Cubification method and engage people by organizing a workshop dedicated to your innovation challenge. Customized materials, ready-to-use kits, practical preparation and facilitation guides are available to create successful workshops!",
    "button": "Discover the shop"
  },

  "footer": {
    "title": "Made with ❤ in Paris",
    "terms": "Terms",
    "contact": "Contact us",
    "redirect": "Site en Français"
  }
}
