import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="alert"
export default class extends Controller {
  static targets = ["alert"]

  connect() {
    this.html = document.querySelector("html")
    this.body = this.element
  }

  hideAlert(event) {
    if (event.target === event.currentTarget) {
      this.alertTarget.classList.add("hidden")
      this.html.classList.remove("no-scroll")
      this.body.classList.remove("no-scroll")
    }
  }

  showAlert() {
    this.alertTarget.classList.remove("hidden")
    this.html.classList.add("no-scroll")
    this.body.classList.add("no-scroll")
  }
}
