import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ["nav"]

  toggleMenu() {
    this.navTarget.classList.toggle("visible")
  }
}
