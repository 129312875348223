import { application } from "./application.js"

import AlertController from "./alert_controller.js"
application.register("alert", AlertController)

import LanguageController from "./language_controller.js"
application.register("language", LanguageController)

import MenuController from "./menu_controller.js"
application.register("menu", MenuController)

import ScrollController from "./scroll_controller.js"
application.register("scroll", ScrollController)
